//
//
//
//
//
//
export default {
  data() {
    const width = window.innerWidth;
    return {
      innerWidth: width
    };
  },

  computed: {},
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},

  //生命周期 - 挂载完成（可以访问DOM元素）
  beforeMount() {
    let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
    let htmlDom = document.getElementsByTagName('html')[0];
    const rate = this.isMobile() ? 37.5 : 192;
    htmlDom.style.fontSize = htmlWidth / rate + 'px'; // 1rem = 10px
  },

  activated() {},

  //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|micromessenger)/i);
      return flag;
    }

  }
};