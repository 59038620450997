import baseRequest from '@/api/baseRequest'

export default {
  login (params) {
    return baseRequest.post('/UserLogin/LoginOrRegister', params)
  },
  // 获取填写表单
  getForm (params) {
    return baseRequest.get('/UserLogin/UserInfo', params)
  },
  // 完善用户个人资料
  improveData (params) {
    return baseRequest.post('/UserLogin/ImproveData', params)
  },
  // 公众号登录
  loginOfPublic (params) {
    return baseRequest.post('/UserLogin/LoginOfPublic', params)
  },
  sendSMS (params) {
    return baseRequest.post('/UserLogin/SendSMS', params)
  },
  SendSMSLogin (params) {
    return baseRequest.post('/UserLogin/SendSMSLogin', params)
  },
  // His登录
  HisLogin (params) {
    return baseRequest.post('/UserLogin/HisLogin', params)
  },
  // 账户手机登录
  UserLogin (params) {
    return baseRequest.post('/UserLogin/UserLogin', params)
  },
  // 添加客户
  AddCustomer (params) {
    return baseRequest.post('/Customer/AddCustomer', params)
  },
  // his静默登录
  silentLogin (params) {
    return baseRequest.post('/UserLogin/SilentLogin', params)
  },
  // his临时登录入口
  tempLogin (params) {
    return baseRequest.post('/UserLogin/HisTempLogin', params)
  }
}
