import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
// import {
//   createPersistedState
// } from './plugin'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false
  },
  actions: {
    loading: ({
      commit
    }, loading = true) => {
      commit('loading', loading)
    }
  },
  mutations: {
    loading: (state, isLoading) => {
      state.loading = isLoading
    }
  },
  getters: {
    loading: (state) => {
      return state.loading
    }
  },
  modules: {
    ...modules,
  }
  // plugins: [
  //   createPersistedState({ // 长期存储关键数据
  //     paths: [
  //       'app',
  //       'user.user.token'
  //     ]
  //   })
  // ]
})
