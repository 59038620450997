/* eslint-disable no-unused-vars */
import { commonApi } from '@/api'
import {
  adminTokenLocal,
  adminReTokenLocal,
  adminCodeLocal,
  adminEvalLocal,
  adminUserLocal
} from '@/storage'
export default {
  namespaced: true,
  state: {
    // 用户信息
    userInfo: {},
    token: '',
    reToken: '',
    qrCode: '',
    evalNo: '',
    loginLoading: false
  },
  mutations: {
    /**
     * @description 设置 store 里的全屏状态
     * @param {Object} state vuex state
     * @param {Boolean} active active
     */
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
      adminUserLocal.setJSON(userInfo)
    },
    setToken (state, token) {
      state.token = token
      adminTokenLocal.setJSON(token)
    },
    setReToken (state, reToken) {
      state.reToken = reToken
      adminReTokenLocal.setJSON(reToken)
    },
    setQrCode (state, qrCode) {
      state.qrCode = qrCode
      adminCodeLocal.setJSON(qrCode)
    },
    setEvalNo (state, evalNo) {
      state.qrCode = evalNo
      adminEvalLocal.setJSON(evalNo)
    },
    setLoginLoading (state, loading) {
      state.loginLoading = loading
    }
  },
  actions: {
    login ({ state, dispatch, commit, rootState }, { params, vm, loginType, route = { name: 'introducePage' } }) {
      commit('setLoginLoading', true)
      commonApi.login(params).then(async res => {
        // 开始请求登录接口
        commit('setUserInfo', {
          username: params.username,
          phone: params.phone,
        })
        vm.$router.replace(route)
        localStorage.removeItem('guideList')
        localStorage.removeItem('gaugeIndex')
        localStorage.removeItem('qaIndex')
        localStorage.removeItem('answerStorage')
      }).finally(()=> {
        commit('setLoginLoading', false)
      })
    },
    hisLogin ({ state, dispatch, commit, rootState }, { params, vm, loginType, route = { name: 'introducePage' } }) {
      commit('setLoginLoading', true)
      commonApi.HisLogin(params).then(async res => {
        // 开始请求登录接口
        commit('setUserInfo', {
          username: params.sysCardNo,
          phone: params.phone,
        })
        vm.$router.replace(route)
        localStorage.removeItem('guideList')
        localStorage.removeItem('gaugeIndex')
        localStorage.removeItem('qaIndex')
        localStorage.removeItem('answerStorage')
      }).finally(()=> {
        commit('setLoginLoading', false)
      })
    },
    // 临时登录录入
    tempLogin ({ state, dispatch, commit, rootState }, { params, vm, loginType, route = { name: 'introducePage' } }) {
      commit('setLoginLoading', true)
      commonApi.tempLogin(params).then(async res => {
        // 开始请求登录接口
        commit('setUserInfo', {
          username: params.sysCardNo,
          phone: params.phone,
        })
        vm.$router.replace(route)
        localStorage.removeItem('guideList')
        localStorage.removeItem('gaugeIndex')
        localStorage.removeItem('qaIndex')
        localStorage.removeItem('answerStorage')
      }).finally(()=> {
        commit('setLoginLoading', false)
      })
    },
    userLogin ({ state, dispatch, commit, rootState }, { params, vm, loginType, route = { name: 'introducePage' } }) {
      commit('setLoginLoading', true)
      commonApi.UserLogin(params).then(async res => {
        // 开始请求登录接口
        commit('setUserInfo', {
          username: params.username,
          phone: params.phone,
        })
        vm.$router.replace(route)
        localStorage.removeItem('guideList')
        localStorage.removeItem('gaugeIndex')
        localStorage.removeItem('qaIndex')
        localStorage.removeItem('answerStorage')
      }).finally(()=> {
        commit('setLoginLoading', false)
      })
    },
    loginOfPublic ({ state, dispatch, commit, rootState }, { params, vm, loginType, route = { name: 'reportPage' } }) {
      commonApi.loginOfPublic(params).then(async res => {
        vm.$router.replace(route)
      })
    },
    silentLogin ({ state, dispatch, commit, rootState }, { params, vm, loginType, route = { name: 'reportPage' } }) {
      commonApi.silentLogin(params).then(async res => {
        vm.$router.replace(route)
      }).finally(()=> {
        commit('setLoginLoading', false)
      })
    },
    afterLogout ({ commit }, { cb, toLogin = true, clearAll = true } = {}) {
      // let type = loginTypeLocal.getOrDefault()
      commit('setUserInfo', {})
      commit('setToken', '')
      commit('setReToken', '')
      commit('setQrCode', '')
      // clearAll && window.localStorage.clear()
      // clearAll && window.sessionStorage.clear()
      cb && cb()
      toLogin
    },
    /**
     * @description 注销用户并返回登录页面
     * @param {Object} param context
     * @param {Object} param vm {Object} vue 实例
     * @param {Object} param confirm {Boolean} 是否需要确认
     */
    logout ({ commit, dispatch }, { vm, confirm = false, cb } = {}) {
      /**
       * @description 注销
       */
      // 判断是否需要确认
      if (confirm) {
        commit('common/setting/setGray', true, { root: true })
        vm.$confirm('确定要注销账户吗?', '确认操作', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            commit('common/setting/setGray', false, { root: true })
            // logout()
            dispatch('afterLogout', { cb })
          })
          .catch(() => {
            commit('common/setting/setGray', false, { root: true })
          })
      } else {
        // logout()
        dispatch('afterLogout', { cb })
      }
    },
  },
  getters: {
    userInfo (state) {
      console.log(state, '321')
      return state.userInfo
    },
    isLogin (state) {
      return state.userInfo && state.userInfo.token && !!state.userInfo.token
    }
  }
}
