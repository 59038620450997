import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import * as echarts from 'echarts/core'
// import { BarChart } from 'echarts/charts'
// import {
//   TitleComponent,
//   TooltipComponent,
//   GridComponent,
//   DatasetComponent,
//   TransformComponent
// } from 'echarts/components'
// import { LabelLayout, UniversalTransition } from 'echarts/features'
// import { CanvasRenderer } from 'echarts/renderers'
// echarts.use([
//   TitleComponent,
//   TooltipComponent,
//   GridComponent,
//   DatasetComponent,
//   TransformComponent,
//   BarChart,
//   LabelLayout,
//   UniversalTransition,
//   CanvasRenderer
// ])

// Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
