const modules = (files) => {
  const storeInfo = {}
  files.keys().forEach(key => {
    storeInfo[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
  })
  return storeInfo
}
export default {
  // common: {
  //   namespaced: true,
  //   modules: modules(require.context('./common', false, /\.js$/))
  // },
  user: {
    namespaced: true,
    modules: modules(require.context('./user', false, /\.js$/))
  }
}
