/* eslint-disable no-unused-vars */
import { Loading } from 'element-ui'
import { appNamespace } from '@/config'
import debounce from '@/utils/debounce'
export default {
  /**
   * @description 更新标题
   * @param {String} title 标题
   */
  title (titleText) {
    const processTitle = process.env.VUE_APP_TITLE || '心身云医'
    window.document.title = `${processTitle}${
      titleText ? ` | ${titleText}` : ''
    }`
  },

  /**
   * @description 打开新页面
   * @param {String} url 地址
   */
  open (url) {
    var a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('target', '_blank')
    a.setAttribute('id', 'mq-menu-link')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(document.getElementById('mq-menu-link'))
  },
  merge (target, source) {
    if (typeof target !== 'object') {
      target = {}
    }
    if (Array.isArray(source)) {
      return source.slice()
    }
    for (const property in source) {
      // eslint-disable-next-line no-prototype-builtins
      if (source.hasOwnProperty(property)) {
        const sourceProperty = source[property]
        if (typeof sourceProperty === 'object') {
          target[property] = this.merge(target[property], sourceProperty)
          continue
        }
        target[property] = sourceProperty
      }
    }
    return target
  },
  cloneObj (obj) {
    if (!obj) return obj
    if (typeof obj !== 'object') return obj
    if (obj instanceof Array) {
      const res = []
      obj.forEach(item => res.push(this.cloneObj(item)))
      return res
    } else {
      const res = {}
      for (const key in obj) {
        res[key] = this.cloneObj(obj[key])
      }
      return res
    }
  },
  arrayToStr (list, getStr) {
    if (!list || list.length === 0) return null
    if (!getStr) {
      getStr = item => {
        return item
      }
    }
    let str = ''
    list.forEach(element => {
      str += getStr(element) + ','
    })
    str = str.substring(0, str.length - 1)
    return str
  },
  dateFmt (date, fmt = 'yyyy-MM-dd') {
    if (!date) {
      return date
    }
    if (typeof date === 'string') {
      date = new Date(date)
    }
    var o = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(), // 日
      'h+': date.getHours(), // 小时
      'm+': date.getMinutes(), // 分
      's+': date.getSeconds(), // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      S: date.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + '').substring(4 - RegExp.$1.length)
      )
    }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ('00' + o[k]).substring(('' + o[k]).length)
        )
      }
    }
    return fmt
  },
  // 数字转中文
  chinanum (digit) {
    digit = typeof digit === 'number' ? String(digit) : digit
    const zh = ['', '一', '二', '三', '四', '五', '六', '七', '八', '九']
    const unit = ['千', '百', '十', '']
    const quot = [
      '万',
      '亿',
      '兆',
      '京',
      '垓',
      '秭',
      '穰',
      '沟',
      '涧',
      '正',
      '载',
      '极',
      '恒河沙',
      '阿僧祗',
      '那由他',
      '不可思议',
      '无量',
      '大数'
    ]
    let breakLen = Math.ceil(digit.length / 4)
    let notBreakSegment = digit.length % 4 || 4
    let segment
    const zeroFlag = []
    const allZeroFlag = []
    let result = ''
    while (breakLen > 0) {
      if (!result) {
        // 第一次执行
        segment = digit.slice(0, notBreakSegment)
        const segmentLen = segment.length
        for (let i = 0; i < segmentLen; i++) {
          if (segment[i] !== 0) {
            if (zeroFlag.length > 0) {
              result += '零' + zh[segment[i]] + unit[4 - segmentLen + i]
              // 判断是否需要加上 quot 单位
              if (i === segmentLen - 1 && breakLen > 1) {
                result += quot[breakLen - 2]
              }
              zeroFlag.length = 0
            } else {
              result += zh[segment[i]] + unit[4 - segmentLen + i]
              if (i === segmentLen - 1 && breakLen > 1) {
                result += quot[breakLen - 2]
              }
            }
          } else {
            // 处理为 0 的情形
            if (segmentLen === 1) {
              result += zh[segment[i]]
              break
            }
            zeroFlag.push(segment[i])
            continue
          }
        }
      } else {
        segment = digit.slice(notBreakSegment, notBreakSegment + 4)
        notBreakSegment += 4
        for (let j = 0; j < segment.length; j++) {
          if (segment[j] !== 0) {
            if (zeroFlag.length > 0) {
              // 第一次执行zeroFlag长度不为0，说明上一个分区最后有0待处理
              if (j === 0) {
                result += quot[breakLen - 1] + zh[segment[j]] + unit[j]
              } else {
                result += '零' + zh[segment[j]] + unit[j]
              }
              zeroFlag.length = 0
            } else {
              result += zh[segment[j]] + unit[j]
            }
            // 判断是否需要加上 quot 单位
            if (j === segment.length - 1 && breakLen > 1) {
              result += quot[breakLen - 2]
            }
          } else {
            // 第一次执行如果zeroFlag长度不为0, 且上一划分不全为0
            if (j === 0 && zeroFlag.length > 0 && allZeroFlag.length === 0) {
              result += quot[breakLen - 1]
              zeroFlag.length = 0
              zeroFlag.push(segment[j])
            } else if (allZeroFlag.length > 0) {
              // 执行到最后
              if (breakLen === 1) {
                result += ''
              } else {
                zeroFlag.length = 0
              }
            } else {
              zeroFlag.push(segment[j])
            }
            if (
              j === segment.length - 1 &&
              zeroFlag.length === 4 &&
              breakLen !== 1
            ) {
              // 如果执行到末尾
              if (breakLen === 1) {
                allZeroFlag.length = 0
                zeroFlag.length = 0
                result += quot[breakLen - 1]
              } else {
                allZeroFlag.push(segment[j])
              }
            }
            continue
          }
        }

        --breakLen
      }
      return result
    }
  },
  // 导出
  async exportData (fun, query, limit = 5000, reCall = false, isApi) {
    if (!reCall) {
      query = Object.assign({}, query)
      query.start = 1
      query.act = 'export'
      query.limit = parseInt(limit)
      query.type = 'page'
    }
    try {
      const result = await fun(query)
      setTimeout(() => {
        Loading.service().close()
      }, 100)
      if (isApi) {
        if (result.url) {
          this.funDownload(result.url)
        }
      } else {
        if (result.count === 0) {
          this.$message.error('暂无数据，无法导出')
        } else {
          //    window.open('/api' + result)
          this.funDownload('/api' + result)
        }
      }
    } catch (error) {
      if (error.status === 2) {
        query.start = error.data.start
        this.exportData(fun, query, parseInt(limit), true)
      }
    }
  },
  funDownload (contentUrl, fileName) {
    // 创建隐藏的可下载链接
    var eleLink = document.createElement('a')
    eleLink.style.display = 'none'
    if (fileName) {
      eleLink.download = fileName
    }
    eleLink.href = contentUrl
    // 触发点击
    document.body.appendChild(eleLink)
    eleLink.click()
    // 然后移除
    document.body.removeChild(eleLink)
  },
  // 获取对象中需要比较的空值数量，params对象，list需要对比的键列表
  getNum (params, list) {
    let num = 0
    for (var key in params) {
      list.filter(item => {
        if (key === item) {
          if (!params[key]) {
            num += 1
          } else {
            num += 0
          }
        }
      })
    }
    return num
  },
  // 获取浏览器缩放比例
  detectZoom () {
    let ratio = 0
    const screen = window.screen
    const ua = navigator.userAgent.toLowerCase()

    if (window.devicePixelRatio !== undefined) {
      ratio = window.devicePixelRatio
    } else if (~ua.indexOf('msie')) {
      if (screen.deviceXDPI && screen.logicalXDPI) {
        ratio = screen.deviceXDPI / screen.logicalXDPI
      }
    } else if (
      window.outerWidth !== undefined &&
      window.innerWidth !== undefined
    ) {
      ratio = window.outerWidth / window.innerWidth
    }

    if (ratio) {
      ratio = Math.round(ratio * 100)
    }

    return ratio
  },
  newObjectData (obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  isFunction (functionToCheck) {
    var getType = {}
    return (
      functionToCheck &&
      getType.toString.call(functionToCheck) === '[object Function]'
    )
  },
  isUndefined (val) {
    // eslint-disable-next-line no-void
    return val === void 0
  },
  isDefined (val) {
    return val !== undefined && val !== null
  },
  // 列表数组去重
  filterGroup (array, key = 'id') {
    const obj = {}
    const result = []
    // array = this.newObjectData(array)
    const len = array.length
    for (let i = len; i--;) {
      // 倒序遍历，性能优化
      const item = array[i]
      if (!obj[item[key]]) {
        result.unshift(item)
        obj[item[key]] = true
      }
    }
    return result
  },
  // 比较两个对象是否相同
  deepCompare (x, y) {
    var i, l, leftChain, rightChain

    function compare2Objects (x, y) {
      var p

      // remember that NaN === NaN returns false
      // and isNaN(undefined) returns true
      if (
        isNaN(x) &&
        isNaN(y) &&
        typeof x === 'number' &&
        typeof y === 'number'
      ) {
        return true
      }

      // Compare primitives and functions.
      // Check if both arguments link to the same object.
      // Especially useful on the step where we compare prototypes
      if (x === y) {
        return true
      }

      // Works in case when functions are created in constructor.
      // Comparing dates is a common scenario. Another built-ins?
      // We can even handle functions passed across iframes
      if (
        (typeof x === 'function' && typeof y === 'function') ||
        (x instanceof Date && y instanceof Date) ||
        (x instanceof RegExp && y instanceof RegExp) ||
        (x instanceof String && y instanceof String) ||
        (x instanceof Number && y instanceof Number)
      ) {
        return x.toString() === y.toString()
      }

      // At last checking prototypes as good as we can
      if (!(x instanceof Object && y instanceof Object)) {
        return false
      }

      // eslint-disable-next-line no-prototype-builtins
      if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
        return false
      }

      if (x.constructor !== y.constructor) {
        return false
      }

      if (x.prototype !== y.prototype) {
        return false
      }

      // Check for infinitive linking loops
      if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
        return false
      }

      // Quick checking of one object being a subset of another.
      // todo: cache the structure of arguments[0] for performance
      for (p in y) {
        // eslint-disable-next-line no-prototype-builtins
        if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
          return false
        } else if (typeof y[p] !== typeof x[p]) {
          return false
        }
      }

      for (p in x) {
        // eslint-disable-next-line no-prototype-builtins
        if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
          return false
        } else if (typeof y[p] !== typeof x[p]) {
          return false
        }

        switch (typeof x[p]) {
          case 'object':
          case 'function':
            leftChain.push(x)
            rightChain.push(y)

            if (!compare2Objects(x[p], y[p])) {
              return false
            }

            leftChain.pop()
            rightChain.pop()
            break

          default:
            if (x[p] !== y[p]) {
              return false
            }
            break
        }
      }

      return true
    }

    if (arguments.length < 1) {
      return true // Die silently? Don't know how to handle such case, please help...
      // throw "Need two or more arguments to compare";
    }

    for (i = 1, l = arguments.length; i < l; i++) {
      leftChain = [] // Todo: this can be cached
      rightChain = []

      if (!compare2Objects(arguments[0], arguments[i])) {
        return false
      }
    }

    return true
  },
  // 深拷贝
  deepClone (target) {
    // 定义一个变量
    let result
    // 如果当前需要深拷贝的是一个对象的话
    if (typeof target === 'object') {
      // 如果是一个数组的话
      if (Array.isArray(target)) {
        result = [] // 将result赋值为一个数组，并且执行遍历
        for (const i in target) {
          // 递归克隆数组中的每一项
          result.push(this.deepClone(target[i]))
        }
        // 判断如果当前的值是null的话；直接赋值为null
      } else if (target === null) {
        result = null
        // 判断如果当前的值是一个RegExp对象的话，直接赋值
      } else if (target.constructor === RegExp) {
        result = target
      } else {
        // 否则是普通对象，直接for in循环，递归赋值对象的所有值
        result = {}
        for (const i in target) {
          result[i] = this.deepClone(target[i])
        }
      }
      // 如果不是对象的话，就是基本数据类型，那么直接赋值
    } else {
      result = target
    }
    // 返回最终结果
    return result
  },
  localStorageSetItem (key, state) {
    window.localStorage.setItem(
      appNamespace + '/' + key,
      JSON.stringify(state)
    )
  },
  localStorageGetItem (key) {
    return JSON.parse(window.localStorage.getItem(appNamespace + '/' + key))
  },
  clearAdminLocalStorage (key) {
    window.localStorage.removeItem(appNamespace + '/' + key)
  },
  // 高精度加法
  add (num1, num2) {
    const num1Digits = (num1.toString().split('.')[1] || '').length
    const num2Digits = (num2.toString().split('.')[1] || '').length
    const baseNum = 10 ** (Math.max(num1Digits, num2Digits) + 1)
    return (num1 * baseNum + num2 * baseNum) / baseNum
  },
  debounce
}
