import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/publicLogin',
    name: 'publicLogin',
    component: () => import('../views/home')
  },
  {
    path: '/hisEvalNavigate',
    name: 'hisEvalNavigate',
    component: () => import('../views/hisEvalNavigate')
  },
  {
    path: '/login',
    name: 'loginPage',
    // component: () => import('../views/login')
    component: () => import('../views/login/hisLogin')
  },
  {
    path: '/registration',
    name: 'registrationPage',
    component: () => import('../views/registration')
  },
  {
    path: '/introduce',
    name: 'introducePage',
    component: () => import('../views/QA/introduce.vue')
  },
  {
    path: '/qa',
    name: 'QAPage',
    component: () => import('../views/QA')
  },
  {
    path: '/result',
    name: 'resultPage',
    component: () => import('../views/result')
  },
  {
    path: '/empower',
    name: 'empowerPage',
    component: () => import('../views/empower')
  },
  {
    path: '/reportPage',
    name: 'reportPage',
    component: () => import('../views/home/components/reportPage.vue')
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import('../views/customer')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior: () => {
    history.pushState(null, null, document.URL)
  }
})


export default router
