import {
  LocalItem
} from '@/storage/src'

// 用户信息
export const adminTokenLocal = new LocalItem({
  name: 'XSY/TOKEN'
})
export const adminReTokenLocal = new LocalItem({
  name: 'XSY/RETOKEN'
})
export const adminCodeLocal = new LocalItem({
  name: 'XSY/CODE'
})
export const adminEvalLocal = new LocalItem({
  name: 'XSY/EVAL'
})
export const adminUserLocal = new LocalItem({
  name: 'XSY/User'
})
// 临时公共页面的key
export const temporaryPubliceKeyLocal = new LocalItem({
  name: 'XSY/TEMPORARY_PUBLICE_KEY'
})
