import baseRequest from '@/api/baseRequest'

export default {
  getInfo (params) {
    return baseRequest.post('/Evaluation/GaugeGroupInfo', params)
  },
  getAnswerType (params) {
    return baseRequest.get('/Evaluation/EvalTypeOption', params)
  },
  // 提交测评
  sendAnswer (params) {
    return baseRequest.post('/Evaluation/Evaluation', params)
  },
  // 获取报告详情
  getReport (evalNo) {
    return baseRequest.get(`/UserReport/UserEval/${evalNo}`)
  },
  // 获取报告列表
  getReportList (params) {
    return baseRequest.post('/UserReport/GaugePageList', params)
  },
  getPublicReport (evalNo, userId) {
    return baseRequest.get(`/UserReport/UserEvalToPublic/${evalNo}/${userId}`)
  }
}
