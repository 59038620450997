/* eslint-disable no-unused-vars */
import axios from 'axios'
import store from '../store'
// import { redirectToIndex, redirectToLoign } from '@/router'
import commonUtil from '@/utils/commonUtil'
import { adminTokenLocal, adminReTokenLocal } from '@/storage'
import appConfig from '../config'
import { remove } from 'lodash'
import { Toast } from 'vant'
const _env = process.env.NODE_ENV
const MINI_TIME = 500
const _requests = []
function log (msg) {
  if (_env === 'development') {
    // console.log(msg)
  }
}
function pushRequest (config) {
  if (!config.loading) return
  _requests.push(config)
  config.loading && store.dispatch('loading')
}

function popRequest (config) {
  if (!config.loading) return
  remove(_requests, r => {
    return r === config
  })
  if (!_requests.length) {
    setTimeout(() => {
      store.dispatch('loading', false)
    }, 300)
  }
}
const getAuthorization = () => {
  const token = adminTokenLocal.getJSON()
  return token ? 'Bearer ' + token : 'Basic em1heF9hZG1pbjp4THg3bXUyMlAwcUg='
}
const getxAuthorization = () => {
  const token = adminTokenLocal.getJSON()
  const reToken = adminReTokenLocal.getJSON()
  if (token !== null && token !== '' && reToken !== null && reToken !== '') {
    try {
      const playload = token.split('.')[1]
      // 将  '-' 替换成 '+', '_' 替换成 '/'
      const base64Url=playload.replace(/-/g,'+').replace(/_/g,'/')
      // 添加需要填充的 '='
      const padding = '='.repeat((4 - base64Url.length % 4) % 4);
      const decodedS=window.atob(base64Url + padding)
      const jwt = JSON.parse(decodedS)
      const exp = new Date(jwt.exp * 1000)
      if (new Date() >= exp) {
        // 过期刷新token
        return 'Bearer ' + reToken
      }
      return ''
    } catch(e) {
      console.log(e)
      return 'Basic em1heF9hZG1pbjp4THg3bXUyMlAwcUg='
    }
    
  }
}
axios.defaults.baseURL = appConfig.BASE_URL
const interceptorsResponse = config => {
  const headers = config.headers
  const token = headers['access-token']
  const reToken = headers['x-access-token']
  if (token != null && reToken != null) {
    adminTokenLocal.setJSON(token)
    adminReTokenLocal.setJSON(reToken)
    // store.commit('user/account/setToken', token)
    // store.commit('user/account/setReToken', reToken)
  }
  return config
}
const base = (
  url,
  data = {},
  method = 'POST',
  isSilence = false,
  path = '',
  {
    removeEmpty = true,
    isUpload = false,
    loading = true
  } = {},
  responseType
) => {
  const _params = !isUpload ? commonUtil.deepClone(data) : data // 此处深拷贝导致图片上传格式错误
  // 去空
  if (removeEmpty) {
    for (var item in _params) {
      if (
        (!_params[item] && _params[item] !== 0) ||
        (Array.isArray(_params[item]) && _params[item].length === 0) ||
        (JSON.stringify(_params[item]) === '{}' && !(_params[item] instanceof File))
      ) {
        delete _params[item]
      }
    }
  }
  // _params.t = t
  // 签名完再添加到参数中去
  const _opts = { method, url: path + url }
  let _timer = null
  if (method.toLocaleUpperCase() === 'POST') {
    _opts.data = _params
  } else {
    _opts.params = _params
  }
  const whiteList = ['/admin/tags/createCinemaTags']
  const whiteIndex = whiteList.findIndex(item => item === url)
  const instance = axios.create({
    timeout: whiteIndex === -1 ? 30000 : 0,
    headers: {
      'content-type': isUpload ? 'multipart/form-data' : (responseType === 'blob' ? 'application/vnd.ms-excel;charset=UTF-8' : 'application/json;charset=UTF-8'),
      'Access-Control-Allow-Origin': '*',
      'api-version': appConfig.API_VERSION,
      Authorization: getAuthorization(),
      'X-Authorization': getxAuthorization()
    },
    responseType
  })
  instance.interceptors.response.use((config) => interceptorsResponse(config), error => {
    let _message = null
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          _message = '签名错误'
          // redirectToIndex()
          break
        case 401:
          store.dispatch('account/loginError', '请登录')
          _message = '签名错误'
          // redirectToIndex()
          break
        case 403:
          _message = '权限过期，请重新登录'
          // redirectToLoign()
          break
        case 404:
          // redirectToIndex()
          break
        case 502:
          _message = '服务器重启中，请稍后重试'
          break
      }
    } else {
      _message = error.msg || '未知错误'
    }
    Toast.fail(_message)
  })

  return new Promise((resolve, reject) => {
    const _random = { stamp: Date.now(), url: _opts.url, loading }
    if (!isSilence) {
      const isGetUrl = url.indexOf('/get') !== -1 // get数据请求小于时间数不加loading层
      _timer = setTimeout(() => {
        pushRequest(_random)
      }, isGetUrl ? MINI_TIME : 0)
    }
    instance(_opts)
      .then(response => {
        clearTimeout(_timer)
        popRequest(_random)
        const data = response.data.data
        const contentType = response.headers['content-type']
        if (/^image\/png.*/.test(contentType)) {
          resolve(data)
        } else if (/^text\/html.*/.test(contentType)) {
          resolve(data)
        } else if (contentType && contentType.indexOf('vnd.ms-excel') !== -1) {
          const fileName = response.headers['content-disposition'].replace(
            'attachment;filename=',
            ''
          )
          resolve({ data, fileName })
        }
        if (data.statusCode === 10000) {
          resolve(data.body)
        } else if (response.request.responseType === 'blob' && data) {
          resolve(data)
        } else {
          switch (data.statusCode) {
            // case 10001:
            case 10002:
            case 10003:
            case 10006:
            case 10008:
            case 100011:
              store.dispatch('user/user/loginError', { confirm: data.msg })
              // redirectToLoign()
              break
            // case 10004:
            // case 10005:
            // case 10007:
          }
          Toast.fail(data.msg)
          reject(data)
        }
      })
      .catch(error => {
        log(error)
        clearTimeout(_timer)
        popRequest(_random)
        Toast.fail('error')
        reject(error)
      })
  })
}
const get = (url, data, method = 'GET', {
  removeEmpty = true,
  isUpload = false,
  loading = true
} = {}, responseType) => {
  return base(url, data, method, false, '', {
    removeEmpty,
    isUpload,
    loading
  }, responseType)
}
const post = (url, data, method = 'POST', {
  removeEmpty = true,
  isUpload = false,
  loading = true
} = {}) => {
  return base(url, data, method, false, '', {
    removeEmpty,
    isUpload,
    loading
  })
}
const del = (url) => {
  return axios.delete(url)
}
export default {
  get,
  post,
  del
}
